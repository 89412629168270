import { turbineTypes } from "utils/constants";

// https://stackoverflow.com/questions/2440700/reordering-arrays/2440723
export function immutableMove(arr, from, to) {
  return arr.reduce((prev, current, idx, self) => {
    if (from === to) {
      prev.push(current);
    }
    if (idx === from) {
      return prev;
    }
    if (from < to) {
      prev.push(current);
    }
    if (idx === to) {
      prev.push(self[from]);
    }
    if (from > to) {
      prev.push(current);
    }
    return prev;
  }, []);
}
export function distinctArray(array, key, attribute) {
  const result = [];
  const map = new Map();
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item[key]) {
      if (attribute) {
        if (item[key][attribute]) {
          if (!map.has(item[key][attribute])) {
            map.set(item[key][attribute], true);
            result.push(item[key]);
          }
        }
      } else if (!map.has(item[key])) {
        map.set(item[key], true);
        result.push(item[key]);
      }
    }
  }
  return result;
}
export function recursiveFind(items, keyToSearch, searchKey = "id") {
  if (items) {
    let result;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const { children } = item;
      if (item[searchKey] === keyToSearch) {
        return item;
      }
      if (children) {
        result = recursiveFind(children, keyToSearch);
        if (result) {
          return result;
        }
      }
    }
  }
  return null;
}

export function findMalfunction(items, id) {
  const result = items.find((item) => item.id === id);
  return result;
}

export function limitNumber(number, min, max) {
  if (!min || !max) {
    return Number.parseFloat(number);
  }
  return Math.min(Math.max(number, min), max);
}

export function saflyCheckArrayLength(arrayToCheck) {
  if (arrayToCheck !== null || arrayToCheck !== undefined) {
    if (Array.isArray(arrayToCheck)) {
      return arrayToCheck.length;
    }
  }
  return 0;
}

export function getTurbinesDropDownItems() {
  const items = [
    {
      id: turbineTypes.FRANCIS,
      name: turbineTypes.FRANCIS,
    },
    {
      id: turbineTypes.FRANCIS_MIV,
      name: turbineTypes.FRANCIS_MIV,
    },
    {
      id: turbineTypes.KAPLAN_HORIZONTAL,
      name: turbineTypes.KAPLAN_HORIZONTAL,
    },
    {
      id: turbineTypes.KAPLAN_VERTICAL,
      name: turbineTypes.KAPLAN_VERTICAL,
    },
    {
      id: turbineTypes.PELTON_MIV,
      name: turbineTypes.PELTON_MIV,
    },
    {
      id: turbineTypes.SYNCHRONOUS_CONDENSER,
      name: turbineTypes.SYNCHRONOUS_CONDENSER,
    },
  ];
  return items;
}
