import React, { Component } from "react";
import Section from "components/layout/Section";
import DropDown from "components/input/DropDown";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { languages } from "utils/components";
import * as Actions from "store/actions";
import { turbineTypes } from "utils/constants";
import i18n from "i18n";
import css from "./SelectLanguage.module.scss";

class SelectLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: {
        languageCode: "en",
        name: "English",
        id: "en",
      },
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    const {
      showModal,
      getMalfunctions,
      getComponents,
    } = this.props;
    const selected = languages.find((language) => language.languageCode === i18n.language);
    if (selected) {
      this.setState({ selectedLanguage: selected });
    }
    // handle language change, updating the state of component/malfunctions
    i18n.on("languageChanged", (lng) => {
      console.log("languageChanged => ".concat(lng));
      showModal({
        type: "wait",
        message: i18n.t("components.loading"),
      });
      getMalfunctions(turbineTypes.FRANCIS);
      getMalfunctions(turbineTypes.FRANCIS_MIV);
      getMalfunctions(turbineTypes.KAPLAN_HORIZONTAL);
      getMalfunctions(turbineTypes.KAPLAN_VERTICAL);
      getMalfunctions(turbineTypes.PELTON_MIV);
      getMalfunctions(turbineTypes.SYNCHRONOUS_CONDENSER);
      getComponents();
    });
  }

  handleOnChange(e) {
    this.setState({ selectedLanguage: e.value.item });
    i18n.changeLanguage(e.value.item.languageCode);
  }

  render() {
    const { selectedLanguage } = this.state;
    return (
      <Section className={css.section}>
        <p>{ i18n.t("components.select_language") }</p>
        <div className={css.dropdown}>
          <DropDown
            placeholder={i18n.t("global.dropDownPlaceholderDefault")}
            items={languages}
            id="select-language"
            update={this.handleOnChange}
            selected={selectedLanguage}
          />
        </div>
      </Section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(SelectLanguage);
